export enum itemActionType {
  AddItem = 'Add',
  RemoveItem = 'Remove',
  AddAllItems = 'AddAllItems',
  UpdateRestaurant = "UpdateRestaurant",
  hideItems = "hideItems",
  toggleVeg = "toggleVeg",
  toggleNonVeg = "toggleNonVeg",
  addFavourites = "addFavourites",
  removeFavourites = "removeFavourites",
  addAddress = "addAddress",
  updateAddress = "updateAddress",
  deleteAddress = "deleteAddress",
  addDefaultAddress = "addDefaultAddress",
  addDeliveryTip = "addDeliveryTip",
  changeYourDetails = "changeYourDetails",
  addOrder = "addOrder",
  reOrder = "reOrder",
  sendMessage = "sendMessage",
  createTempUser = "createNewUser"
}
