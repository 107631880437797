import { Component, ElementRef, ViewChild } from '@angular/core';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {


  constructor() {
    // CapacitorGoogleMaps.initialize({
    //   key: "AIzaSyCz3C0aykWWCbAC4nQO5-uvdF8UWcFk4iQ"
    //  });
  }


}
