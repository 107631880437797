import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot,Router } from '@angular/router';
import { Base } from 'src/app/models/base';
import { Preferences } from '@capacitor/preferences';

var jsonToken;
var userDetails;

const setJsonToken = async (key,value) => {
  await Preferences.set({
    key: key,
    value: value,
  });
};

const getJsonToken = async (key) => {
  const { value } = await Preferences.get({ key: key});

  if(key == 'userdetails'){
    userDetails = `${value}`;
  }

  if(key == 'Authorization'){
    jsonToken = `${value}`;
  }

  console.log('capacitor ' + `${value}`);
  return `${value}`;
};


@Injectable()
export class AuthActivateRouteGuard implements CanActivate {
    user: Base;
    
    constructor(private router: Router){

    }

    async canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot){
        console.log("inRouteGuard");
        this.user = JSON.parse(sessionStorage.getItem('userdetails'));
        var value = (await Preferences.get({ key: 'userdetails'})).value;
        //console.log("getting direct userDetails : ", value);
        
        if(!this.user){
            console.log("inside not user : ");
            if(!value){
                console.log("inside not value : ");
                this.router.navigateByUrl('restaurant/login');
            }else{
                console.log("inside else value : ");
                return value?true:false;
            }   
        }
        
        return this.user?true:false;
    }

}