import { Injectable } from '@angular/core';
import { HttpInterceptor,HttpRequest,HttpHandler,HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import { Base } from '../models/base';
import { Preferences } from '@capacitor/preferences';

var jsonToken;
var userDetails;
Preferences.get({ key: 'Authorization'}).then((response) => jsonToken = response.value);
Preferences.get({ key: 'userdetails'}).then((response) => userDetails = response.value);

@Injectable()
export class XhrInterceptor implements HttpInterceptor {

  user: Base;
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    console.log("insideIntercept", next);
    let httpHeaders = new HttpHeaders();
    this.user = JSON.parse(sessionStorage.getItem('userdetails'));

    if(this.user && this.user.password && this.user.mobileNo){
      console.log("interceptor: inside this.user : ", this.user);
      httpHeaders = httpHeaders.append('Authorization', 'Basic ' + btoa(this.user.mobileNo + ':' + this.user.password));
    }else{
      console.log("interceptor: inside else : ", sessionStorage.getItem('Authorization'));
      console.log("interceptor: inside else : ", jsonToken);
      let authorization = sessionStorage.getItem('Authorization');

      if(authorization){
        httpHeaders = httpHeaders.append('Authorization', authorization);
      }else if(jsonToken != null && jsonToken != undefined){
        sessionStorage.setItem('Authorization', jsonToken);
        httpHeaders = httpHeaders.append('Authorization', jsonToken);
      }
    }

    let xsrf = sessionStorage.getItem('XSRF-TOKEN');
    if(xsrf){
      httpHeaders = httpHeaders.append('X-XSRF-TOKEN', xsrf);  
    }
   httpHeaders = httpHeaders.append('X-Requested-With', 'XMLHttpRequest');
    const xhr = req.clone({
      headers: httpHeaders
    });
    console.log('headers in interceptors: ', httpHeaders);
  return next.handle(xhr).pipe(tap(() => {
  },
      (err: any) => {
        console.log("something went wrong : ", err);
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            this.logoutUser();
            return;
          }
          this.logoutUser();
          this.router.navigate(['login']);
        }
      }));
  }

  logoutUser(){
    window.sessionStorage.removeItem('userdetails');
    window.sessionStorage.removeItem('Authorization');
    Preferences.remove({ key: 'userdetails'});
    Preferences.remove({ key: 'Authorization'});
  }

  // async getDataFromStorage() {
  //   const { value } = await Preferences.get({ key: 'userdetails'});
  //   return JSON.parse(`${value}`);
  // }
}