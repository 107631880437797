
import { Menu } from "./menu";
import { Item } from "./item";
import { Cousine } from "./cousine";
import { Offer } from "./offer";
import { Feed } from "./feed";


export class Restaurant{

    constructor(obj) {
        obj && Object.assign(this, obj);
      }

    id: String;
    restaurantOn: boolean;
    enabled: boolean;
    deliveryOn: boolean;
    title: String;
    thumb: String;
    rating: Number;
    kmAway: String;
    description: String;
    address: String;
    timeToReach: String;
    openingTime: String;
    closingTime: String;
    clossesIn: String;
    menu: Menu;
    itemIdList: Number[];
    numberOfItems: Number;
    items: Item[];
    cousines: Cousine[];
    offers: Offer[];
    deliveryFee: number;
    deliveryTip: number;
    taxesCharger: number;
    welcomeImage: String;
    bottomImage: String;
    localWelcomeImage: any;
    localBottomImage: any;
    goodFeeds: Feed[];
    badFeeds: Feed[];
    longitude: string;
    latitude: string;
}