import { Address } from "./address";
import { Item } from "./item";


export class Cart{

    //Need to be remove in future
    restaurant: any;

    restaurantId: String;
    cartItems: Item[];
    itemTotal: number; 
    discountApplied: number;
    toPay: number;
    defaultAddress: Address;
    orderStatus: String; 
    tip: number;
}
