import {itemActionType} from '../shared/enum/item-action-type';
import {ActionParent} from '../actions/base.action';
import {Item} from '../models/item'
import { Store } from '@ngrx/store';
import { Base } from '../models/base';
import { Cart } from '../models/cart';
import { Restaurant } from '../models/restaurant';
import { EventEmitter } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

var restaurant;
// restaurant.ID=0;
//restaurant.itemIdList = [];

var cart = new Cart();
// cart.restaurant = new Restaurant();
var itemNumberEmitter;



var base = undefined;

sel: Number;

export const initialState: Base[] = [
    base
]

export function BaseReducer (state = initialState, action: ActionParent){
        switch(action.type){
            case itemActionType.createTempUser: {
                console.log("Inside : UpdteRestaurant Reducer: ")
                    let user = [];
                    const copyOfItems = state.map(
                        i => {
                            user.push(action.payload);
                            console.log("pushing: actiondata: ", action.payload);
                            window.sessionStorage.setItem("userdetails",JSON.stringify(action.payload));
                            Preferences.set({ key: "userdetails", value: JSON.stringify(action.payload), });
                        }
                      );
                      console.log("returning  : ", [...user]);
                      return [...user];
            }
            case itemActionType.AddItem:
                {
                    let newBase = [];
                    const copyOfItems = state.map(
                        i => {
                            console.log("i: ", i);
                            base = new Base();
                            cart = new Cart();

                           // restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));

                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;
                            cart.restaurant = restaurant;
                            cart.defaultAddress = i.appDetails.cart.defaultAddress;

                            if(i.appDetails.cart.restaurant[0].ID == action.payload.restaurantId){

                                if(i.appDetails.cart.restaurant[0].itemIdList.includes(action.payload.id)){
                                    cart.restaurant[0].ID = action.payload.restaurantId;
                                    cart.restaurant[0].numberOfItems = i.appDetails.cart.restaurant[0].numberOfItems + 1;

                                    cart.restaurant[0].menu.category_List.forEach(element => {
                                            element.items.forEach(el => {
                                                if(el.id == action.payload.id){
                                                    el.selected = el.selected + 1;
                                                }
                                            });

                                    });

                                    cart.restaurant[0].itemIdList = [...i.appDetails.cart.restaurant[0].itemIdList]

                                    cart.restaurant[0].items.forEach((element, index) => {
                                        if(element.id == action.payload.id){
                                            cart.restaurant[0].items[index].selected = cart.restaurant[0].items[index].selected + 1;
                                            if(cart.restaurant[0].items[index].haveRestaurantOffer){
                                                let discountPrice;
                                                discountPrice = cart.restaurant[0].items[index].price - cart.restaurant[0].items[index].offerPrice
                                                cart.discountApplied = parseInt(i.appDetails.cart.discountApplied.toString()) + discountPrice;
                                                cart.toPay = i.appDetails.cart.toPay + cart.restaurant[0].items[index].offerPrice;
                                                cart.restaurant[0].items[index].itemTotalPrice = cart.restaurant[0].items[index].itemTotalPrice + cart.restaurant[0].items[index].offerPrice;
                                            }else{
                                                cart.discountApplied = i.appDetails.cart.discountApplied;
                                                cart.toPay = i.appDetails.cart.toPay + cart.restaurant[0].items[index].price;
                                                cart.restaurant[0].items[index].itemTotalPrice = cart.restaurant[0].items[index].itemTotalPrice + cart.restaurant[0].items[index].price;
                                            }
                                            cart.itemTotal = i.appDetails.cart.itemTotal+cart.restaurant[0].items[index].price;
                                           // cart.toPay = (cart.itemTotal - cart.discountApplied) + i.appDetails.cart.deliveryTip;
                                          //  cart.deliveryTip = i.appDetails.cart.deliveryTip;

                                          //  cart.taxesCharger = i.appDetails.cart.taxesCharger;
                                           // cart.deliveryFee = i.appDetails.cart.deliveryFee;

                                        }
                                    });

                                    //update it in subcategory
                                    cart.restaurant[0].menu.category_List.forEach(element => {
                                        element.subCategories.forEach(subcategory => {
                                            subcategory.items.forEach(item => {
                                                if(item.id == action.payload.id){
                                                    item.selected = item.selected + 1;
                                                }
                                            });
                                        });

                                });

                                //Remove it after all impl
                                    base.appDetails.addressList = [...i.appDetails.addressList]
                                    base.appDetails.favourites = i.appDetails.favourites;
                                    base.appDetails.cart = cart;
                                    base.appDetails.coupouns = i.appDetails.coupouns;
                                    base.appDetails.orders = i.appDetails.orders;
                                    base.appDetails.recentSearches = i.appDetails.recentSearches;
                                    base.appDetails.chats = i.appDetails.chats;
                                    //base.appDetails.itemList = i.appDetails.cart.itemList;
                                    //base.appDetails.user = i.appDetails.user;
                                    newBase.push(base);
                                }else{

                                    var j = JSON.parse(JSON.stringify(action.payload));
                                    cart.restaurant[0].numberOfItems = i.appDetails.cart.restaurant[0].numberOfItems + 1;
                                    cart.restaurant[0].itemIdList = [...i.appDetails.cart.restaurant[0].itemIdList,action.payload.id]
                                    cart.restaurant[0].ID = action.payload.restaurantId;

                                    if(j.haveRestaurantOffer){
                                        let discountPrice;
                                        discountPrice = j.price - j.offerPrice
                                        cart.discountApplied = parseInt(i.appDetails.cart.discountApplied.toString()) + discountPrice;
                                      //  cart.toPay = i.appDetails.cart.toPay + j.offerPrice + i.appDetails.cart.deliveryTip;
                                        j.itemTotalPrice = j.itemTotalPrice + j.offerPrice;
                                    }else{
                                        cart.discountApplied = i.appDetails.cart.discountApplied;
                                      //  cart.toPay = i.appDetails.cart.toPay + j.price + i.appDetails.cart.deliveryTip;
                                        j.itemTotalPrice = j.itemTotalPrice + j.price;
                                    }
                                    cart.itemTotal = i.appDetails.cart.itemTotal+j.price;
                                    // cart.deliveryTip = i.appDetails.cart.deliveryTip;
                                    // cart.taxesCharger = i.appDetails.cart.taxesCharger;
                                    // cart.deliveryFee = i.appDetails.cart.deliveryFee;
                                    // cart.name = i.appDetails.cart.name;
                                    // cart.phone = i.appDetails.cart.phone;
                                    // cart.toPay = (cart.itemTotal - cart.discountApplied) + i.appDetails.cart.deliveryTip;


                                    cart.restaurant[0].items = [...i.appDetails.cart.restaurant[0].items,j];

                                    cart.restaurant[0].menu.category_List.forEach(category => {

                                        category.items.forEach(item => {
                                                if(item.id == action.payload.id){
                                                    item.selected = item.selected + 1;
                                                }
                                            });

                                    });

                                    //subcategory changes
                                    cart.restaurant[0].menu.category_List.forEach(element => {
                                        element.subCategories.forEach(subcategory => {
                                            subcategory.items.forEach(item => {
                                                if(item.id == action.payload.id){
                                                    item.selected = item.selected + 1;
                                                }
                                            });
                                        });
                                    });

                                    base.appDetails.addressList = [...i.appDetails.addressList]
                                    base.appDetails.favourites = i.appDetails.favourites;
                                    base.appDetails.cart = cart;
                                    base.appDetails.coupouns = i.appDetails.coupouns;
                                    base.appDetails.orders = i.appDetails.orders;
                                    base.appDetails.recentSearches = i.appDetails.recentSearches;
                                    base.appDetails.chats = i.appDetails.chats;
                                   // base.appDetails.user = i.appDetails.user;
                                    newBase.push(base);
                                }

                            }else{
                                console.log("emmit error message as the restaurant is -")
                            }
                        }
                      );
                      console.log("Reducer: ", [...newBase]);
                      itemNumberEmitter = new EventEmitter<Number>();
                      itemNumberEmitter.emit(1);
                    return [...newBase];
                }
            case itemActionType.RemoveItem:
                 {
                    let newBase = [];
                    let newCartItemList = [];

                    const copyOfItems = state.map(
                        i => {

                            base = new Base();
                            cart = new Cart();

                            restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));
                            cart.restaurant = restaurant;
                            cart.defaultAddress = i.appDetails.cart.defaultAddress;

                            for(var k=0;k<cart.restaurant[0].items.length;k++){
                                if(cart.restaurant[0].items[k].id ==  action.payload.id){
                                    if(parseInt(cart.restaurant[0].items[k].selected) > 1){
                                        cart.restaurant[0].items[k].selected = cart.restaurant[0].items[k].selected-1;


                                        if(cart.restaurant[0].items[k].haveRestaurantOffer){
                                            let discountPrice;
                                            discountPrice = cart.restaurant[0].items[k].price - cart.restaurant[0].items[k].offerPrice
                                            cart.discountApplied = parseInt(i.appDetails.cart.discountApplied.toString()) - discountPrice;
                                            cart.toPay = i.appDetails.cart.toPay - cart.restaurant[0].items[k].offerPrice;
                                            cart.restaurant[0].items[k].itemTotalPrice = cart.restaurant[0].items[k].itemTotalPrice - cart.restaurant[0].items[k].offerPrice;
                                        }else{
                                            cart.discountApplied = i.appDetails.cart.discountApplied;
                                            cart.toPay = i.appDetails.cart.toPay - cart.restaurant[0].items[k].price;
                                            cart.restaurant[0].items[k].itemTotalPrice = cart.restaurant[0].items[k].itemTotalPrice - cart.restaurant[0].items[k].price;
                                        }
                                        cart.itemTotal = i.appDetails.cart.itemTotal - cart.restaurant[0].items[k].price;
                                        // cart.toPay = (cart.itemTotal - cart.discountApplied) + i.appDetails.cart.deliveryTip;
                                        // cart.deliveryTip = i.appDetails.cart.deliveryTip;


                                    }else{
                                        var filtered = cart.restaurant[0].itemIdList.filter(function(value, index, arr){

                                            if(cart.restaurant[0].items[k].haveRestaurantOffer){
                                                let discountPrice;
                                                discountPrice = cart.restaurant[0].items[k].price - cart.restaurant[0].items[k].offerPrice
                                                cart.discountApplied = parseInt(i.appDetails.cart.discountApplied.toString()) - discountPrice;
                                                cart.toPay = i.appDetails.cart.toPay - cart.restaurant[0].items[k].offerPrice;
                                                cart.restaurant[0].items[k].itemTotalPrice = cart.restaurant[0].items[k].itemTotalPrice - cart.restaurant[0].items[k].offerPrice;
                                            }else{
                                                cart.discountApplied = i.appDetails.cart.discountApplied;
                                                cart.toPay = i.appDetails.cart.toPay - cart.restaurant[0].items[k].price;
                                                cart.restaurant[0].items[k].itemTotalPrice = cart.restaurant[0].items[k].itemTotalPrice - cart.restaurant[0].items[k].price;
                                            }
                                            cart.itemTotal = i.appDetails.cart.itemTotal - cart.restaurant[0].items[k].price;
                                            // cart.toPay = (cart.itemTotal - cart.discountApplied) + i.appDetails.cart.deliveryTip;
                                            // cart.deliveryTip = i.appDetails.cart.deliveryTip;

                                            return value != action.payload.id;

                                        });
                                        cart.restaurant[0].itemIdList = filtered;
                                        cart.restaurant[0].items.splice(k,1);
                                    }
                                }
                            }
                                //Updating Menu
                            cart.restaurant[0].menu.category_List.forEach(element => {
                                    element.items.forEach(el => {
                                        if(el.id == action.payload.id){
                                            el.selected = el.selected - 1;
                                        }
                                    });
                                });

                                //Updating Subcategory
                            cart.restaurant[0].menu.category_List.forEach(element => {
                                element.subCategories.forEach(subcategory => {
                                    subcategory.items.forEach(item => {
                                        if(item.id == action.payload.id){
                                            item.selected = item.selected - 1;
                                        }
                                    });
                                });
                            });

                            cart.restaurant[0].numberOfItems = i.appDetails.cart.restaurant[0].numberOfItems - 1;

                            //Remove it after all impl
                            // cart.taxesCharger = i.appDetails.cart.taxesCharger;
                            // cart.deliveryFee = i.appDetails.cart.deliveryFee;
                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;
                            //Remove it after all impl
                            base.appDetails.addressList = [...i.appDetails.addressList]
                            base.appDetails.favourites = i.appDetails.favourites;
                            base.appDetails.cart = cart;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.orders = i.appDetails.orders;
                            base.appDetails.recentSearches = i.appDetails.recentSearches;
                            base.appDetails.chats = i.appDetails.chats;
                           // base.appDetails.user = i.appDetails.user;
                            newBase.push(base);
                        }
                      );
                      console.log("Reducer: ", [...newBase]);
                      return [...newBase];
                }
                case itemActionType.AddAllItems:
                {
                    // let newBase = [];
                    // const copyOfItems = state.map(
                    //     i => {
                    //         base = new Base();
                    //         base.appDetails.itemList = action.payload;
                    //         base.appDetails.cart = i.appDetails.cart;
                    //         base.appDetails.user = i.appDetails.user;
                    //         newBase.push(base);
                    //     }
                    //   );
                    //   return [...newBase];
                }
                case itemActionType.addFavourites:
                {

                    console.log("inside: addFavourites");
                    let newBase = [];
                    base = new Base();
                    const copyOfItems = state.map(
                        i => {

                            base = JSON.parse(JSON.stringify(i));

                            base.appDetails.cart.restaurant[0].menu.category_List.forEach(element => {
                                element.subCategories.forEach(subcategory => {
                                    subcategory.items.forEach(item => {
                                        if(item.id == action.payload.id){
                                            item.isFav = true;
                                        }
                                    });
                                });
                            });

                            base.appDetails.cart.restaurant[0].menu.category_List.forEach(category => {
                                category.items.forEach(item => {
                                        if(item.id == action.payload.id){
                                            item.isFav = true;
                                        }
                                });
                            });

                            let item: Item;
                            item = JSON.parse(JSON.stringify(action.payload));
                            item.isFav = true;
                            base.appDetails.addressList = [...i.appDetails.addressList]
                            base.appDetails.favourites = [...i.appDetails.favourites,item.id];
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.chats = i.appDetails.chats;
                            newBase.push(base);
                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
                case itemActionType.removeFavourites:
                {

                    console.log("inside: addFavourites");
                    let newBase = [];
                    let newItem = new Array;
                    base = new Base();
                    const copyOfItems = state.map(
                        i => {

                            base = JSON.parse(JSON.stringify(i));

                            base.appDetails.cart.restaurant[0].menu.category_List.forEach(element => {
                                element.subCategories.forEach(subcategory => {
                                    subcategory.items.forEach(item => {
                                        if(item.id == action.payload.id){
                                            item.isFav = false;
                                        }
                                    });
                                });
                            });

                            base.appDetails.cart.restaurant[0].menu.category_List.forEach(category => {
                                category.items.forEach(item => {
                                        if(item.id == action.payload.id){
                                            item.isFav = false;
                                        }
                                });
                            });

                            base.appDetails.favourites.forEach(id => {
                                if(id != action.payload.id){
                                    newItem.push(action.payload);
                                }
                            });
                            base.appDetails.addressList = [...i.appDetails.addressList]
                            base.appDetails.favourites = newItem;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.chats = i.appDetails.chats;
                            newBase.push(base);
                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
                case itemActionType.UpdateRestaurant:
                {
                
                  console.log("Inside : UpdteRestaurant Reducer: ")
                    let user = [];
                    const copyOfItems = state.map(
                        i => {
                            user.push(action.payload);
                            console.log("pushing: actiondata: ", action.payload);
                            window.sessionStorage.setItem("userdetails",JSON.stringify(action.payload));
                            Preferences.set({ key: "userdetails", value: JSON.stringify(action.payload), });
                        }
                      );
                      console.log("returning  : ", [...user]);
                      return [...user];
                }
                case itemActionType.hideItems:
                {
                    let newBase = [];
                    const copyOfItems = state.map(
                        i => {

                            base = new Base();
                            cart = new Cart();

                            restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));
                            cart.restaurant = restaurant;

                            cart.restaurant[0].menu.category_List.forEach(element => {
                                if(element.id == action.payload.item.id){
                                   element.visibility = action.payload.isVisible;
                                   return;
                                }
                            });

                            //Remove it after all impl
                            cart.itemTotal = i.appDetails.cart.itemTotal;
                           // cart.deliveryTip = i.appDetails.cart.deliveryTip;
                            cart.discountApplied = i.appDetails.cart.discountApplied;
                            // cart.taxesCharger = i.appDetails.cart.taxesCharger;
                            // cart.deliveryFee = i.appDetails.cart.deliveryFee;
                            cart.toPay = i.appDetails.cart.toPay;
                            cart.defaultAddress = i.appDetails.cart.defaultAddress;
                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;
                            //Remove it after all impl

                            base.appDetails.addressList = [...i.appDetails.addressList];
                            base.appDetails.cart = cart;
                            base.appDetails.favourites = i.appDetails.favourites;
                            base.appDetails.orders = i.appDetails.orders;
                            //base.appDetails.user = i.appDetails.user;
                            base.appDetails.chats = i.appDetails.chats;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.recentSearches = i.appDetails.recentSearches;
                            newBase.push(base);

                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
                case itemActionType.toggleVeg:
                {
                    let newBase = [];
                    const copyOfItems = state.map(
                        i => {

                            base = new Base();
                            cart = new Cart();

                            restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));
                            cart.restaurant = restaurant;

                            cart.restaurant[0].menu.category_List.forEach(element => {
                                element.items.forEach(item => {
                                    if(item.subCategory == "veg"){
                                        item.visibility = action.payload;
                                    }
                                }); 
                            });

                            //Remove it after all impl
                            cart.itemTotal = i.appDetails.cart.itemTotal;
                           // cart.deliveryTip = i.appDetails.cart.deliveryTip;
                            cart.discountApplied = i.appDetails.cart.discountApplied;
                            // cart.taxesCharger = i.appDetails.cart.taxesCharger;
                            // cart.deliveryFee = i.appDetails.cart.deliveryFee;
                            cart.toPay = i.appDetails.cart.toPay;
                            cart.defaultAddress = i.appDetails.cart.defaultAddress;
                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;
                            //Remove it after all impl

                            base.appDetails.addressList = [...i.appDetails.addressList];
                            base.appDetails.favourites = i.appDetails.favourites;
                            base.appDetails.cart = cart;
                           // base.appDetails.user = i.appDetails.user;
                            base.appDetails.chats = i.appDetails.chats;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.orders = i.appDetails.orders;
                            base.appDetails.recentSearches = i.appDetails.recentSearches;
                            newBase.push(base);

                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
                case itemActionType.toggleNonVeg:
                {
                    let newBase = [];
                    const copyOfItems = state.map(
                        i => {

                            base = new Base();
                            cart = new Cart();

                            restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));
                            cart.restaurant = restaurant;

                            cart.restaurant[0].menu.category_List.forEach(element => {
                                element.items.forEach(item => {
                                    if(item.subCategory == "nonveg"){
                                        item.visibility = action.payload;
                                    }
                                });
                            });

                            //Remove it after all impl
                            cart.itemTotal = i.appDetails.cart.itemTotal;
                            //cart.deliveryTip = i.appDetails.cart.deliveryTip;
                            cart.discountApplied = i.appDetails.cart.discountApplied;
                            // cart.taxesCharger = i.appDetails.cart.taxesCharger;
                            // cart.deliveryFee = i.appDetails.cart.deliveryFee;
                            cart.toPay = i.appDetails.cart.toPay;
                            cart.defaultAddress = i.appDetails.cart.defaultAddress;
                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;
                            //Remove it after all impl

                            base.appDetails.addressList = [...i.appDetails.addressList];
                            base.appDetails.favourites = i.appDetails.favourites;
                            base.appDetails.cart = cart;
                            base.appDetails.orders = i.appDetails.orders;
                            //base.appDetails.user = i.appDetails.user;
                            base.appDetails.chats = i.appDetails.chats;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.recentSearches = i.appDetails.recentSearches;
                            newBase.push(base);

                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
                case itemActionType.addAddress:
                {
                    let newBase = [];
                    let addressList = [];
                    const copyOfItems = state.map(
                        i => {

                            base = new Base();
                            cart = new Cart();

                            restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));
                            addressList = JSON.parse(JSON.stringify(i.appDetails.addressList));
                            cart.restaurant = restaurant;

                            //Remove it after all impl
                            cart.itemTotal = i.appDetails.cart.itemTotal;
                           // cart.deliveryTip = i.appDetails.cart.deliveryTip;
                            cart.discountApplied = i.appDetails.cart.discountApplied;
                            // cart.taxesCharger = i.appDetails.cart.taxesCharger;
                            // cart.deliveryFee = i.appDetails.cart.deliveryFee;
                            cart.toPay = i.appDetails.cart.toPay;
                            cart.defaultAddress = i.appDetails.cart.defaultAddress;
                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;
                            //Remove it after all impl\\

                            base.appDetails.addressList = [...addressList,action.payload];

                            base.appDetails.favourites = i.appDetails.favourites;
                            base.appDetails.cart = cart;
                            base.appDetails.orders = i.appDetails.orders;
                           // base.appDetails.user = i.appDetails.user;
                            base.appDetails.chats = i.appDetails.chats;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.recentSearches = i.appDetails.recentSearches;
                            newBase.push(base);

                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
                case itemActionType.updateAddress:
                {
                    let newBase = [];
                    let addressList = [];
                    const copyOfItems = state.map(
                        i => {

                            base = new Base();
                            cart = new Cart();

                            restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));
                            addressList = JSON.parse(JSON.stringify(i.appDetails.addressList));
                            cart.restaurant = restaurant;

                            //Remove it after all impl
                            cart.itemTotal = i.appDetails.cart.itemTotal;
                           // cart.deliveryTip = i.appDetails.cart.deliveryTip;
                            cart.discountApplied = i.appDetails.cart.discountApplied;
                            // cart.taxesCharger = i.appDetails.cart.taxesCharger;
                            // cart.deliveryFee = i.appDetails.cart.deliveryFee;
                            cart.toPay = i.appDetails.cart.toPay;
                            cart.defaultAddress = i.appDetails.cart.defaultAddress;
                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;
                            //Remove it after all impl

                            base.appDetails.addressList = [];
                            addressList.forEach(addres =>{

                              console.log("action.payload.addressListId", action.payload.addressListId);
                              console.log("addres.addressListId", addres.addressListId);

                              if(action.payload.addressListId == addres.addressListId){
                                console.log("inside: if ");
                                console.log("action.payload.addressListId", action.payload.addressListId);
                                console.log("addres.addressListId", addres.addressListId);

                                base.appDetails.addressList.push(action.payload);
                              }else{
                                console.log("inside: else ");
                                console.log("action.payload.addressListId", action.payload.addressListId);
                                console.log("addres.addressListId", addres.addressListId);
                                base.appDetails.addressList.push(addres);
                              }

                            });
                            base.appDetails.favourites = i.appDetails.favourites;
                            base.appDetails.cart = cart;
                            base.appDetails.orders = i.appDetails.orders;
                           // base.appDetails.user = i.appDetails.user;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.chats = i.appDetails.chats;
                            base.appDetails.recentSearches = i.appDetails.recentSearches;
                            newBase.push(base);

                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
                case itemActionType.deleteAddress:
                {
                    let newBase = [];
                    let addressList = [];
                    const copyOfItems = state.map(
                        i => {

                            base = new Base();
                            cart = new Cart();

                            restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));
                            addressList = JSON.parse(JSON.stringify(i.appDetails.addressList));
                            cart.restaurant = restaurant;

                            //Remove it after all impl
                            cart.itemTotal = i.appDetails.cart.itemTotal;
                           // cart.deliveryTip = i.appDetails.cart.deliveryTip;
                            cart.discountApplied = i.appDetails.cart.discountApplied;
                            // cart.taxesCharger = i.appDetails.cart.taxesCharger;
                            // cart.deliveryFee = i.appDetails.cart.deliveryFee;
                            cart.toPay = i.appDetails.cart.toPay;
                            cart.defaultAddress = i.appDetails.cart.defaultAddress;
                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;
                            //Remove it after all impl

                            base.appDetails.addressList = [];
                            addressList.forEach(addres =>{

                              console.log("action.payload.addressListId", action.payload.addressListId);
                              console.log("addres.addressListId", addres.addressListId);

                              if(action.payload.addressListId == addres.addressListId){

                              }else{
                                console.log("inside: else ");
                                console.log("action.payload.addressListId", action.payload.addressListId);
                                console.log("addres.addressListId", addres.addressListId);
                                base.appDetails.addressList.push(addres);
                              }

                            });
                            base.appDetails.favourites = i.appDetails.favourites;

                            base.appDetails.cart = cart;
                            base.appDetails.orders = i.appDetails.orders;
                           // base.appDetails.user = i.appDetails.user;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.chats = i.appDetails.chats;
                            base.appDetails.recentSearches = i.appDetails.recentSearches;
                            newBase.push(base);

                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
                case itemActionType.addDefaultAddress:
                {
                    console.log("");
                    let newBase = [];
                    let addressList = [];
                    const copyOfItems = state.map(
                        i => {

                            base = new Base();
                            cart = new Cart();

                            restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));
                            addressList = JSON.parse(JSON.stringify(i.appDetails.addressList));
                            cart.restaurant = restaurant;

                            //Remove it after all impl
                            cart.itemTotal = i.appDetails.cart.itemTotal;
                            //cart.deliveryTip = i.appDetails.cart.deliveryTip;
                            cart.discountApplied = i.appDetails.cart.discountApplied;
                            // cart.taxesCharger = i.appDetails.cart.taxesCharger;
                            // cart.deliveryFee = i.appDetails.cart.deliveryFee;
                            cart.toPay = i.appDetails.cart.toPay;
                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;

                            console.log("Setting the default Address: ", action.payload);
                            cart.defaultAddress = action.payload;
                            //Remove it after all impl
                            base.appDetails.address = [...addressList];
                            base.appDetails.favourites = i.appDetails.favourites;
                            base.appDetails.cart = cart;
                            base.appDetails.orders = i.appDetails.orders;
                           // base.appDetails.user = i.appDetails.user;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.chats = i.appDetails.chats;
                            base.appDetails.recentSearches = i.appDetails.recentSearches;
                            newBase.push(base);

                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
                case itemActionType.addDeliveryTip:
                {
                  console.log("");
                    let newBase = [];
                    let addressList = [];
                    const copyOfItems = state.map(
                        i => {

                            base = new Base();
                            cart = new Cart();

                            restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));
                            addressList = JSON.parse(JSON.stringify(i.appDetails.addressList));
                            cart.restaurant = restaurant;

                            //Remove it after all impl
                            cart.itemTotal = i.appDetails.cart.itemTotal;
                            cart.discountApplied = i.appDetails.cart.discountApplied;
                            // cart.taxesCharger = i.appDetails.cart.taxesCharger;
                            // cart.deliveryFee = i.appDetails.cart.deliveryFee;

                            // if(i.appDetails.cart.deliveryTip != 0){
                            //     cart.toPay = i.appDetails.cart.toPay - i.appDetails.cart.deliveryTip;
                            //     cart.toPay = cart.toPay + action.payload;
                            // }else{
                            //     cart.toPay = i.appDetails.cart.toPay + action.payload;
                            // }

                            // cart.deliveryTip = action.payload;  
                            // cart.defaultAddress = i.appDetails.cart.defaultAddress;
                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;

                            base.appDetails.recentSearches = [...i.appDetails.recentSearches];
                            base.appDetails.addressList = [...addressList];
                            base.appDetails.favourites = i.appDetails.favourites;
                            base.appDetails.cart = cart;
                            base.appDetails.orders = i.appDetails.orders;
                           // base.appDetails.user = i.appDetails.user;
                            base.appDetails.chats = i.appDetails.chats;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.recentSearches = i.appDetails.recentSearches;
                            newBase.push(base);

                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
                case itemActionType.changeYourDetails:
                {
                  console.log("");
                    let newBase = [];
                    let addressList = [];
                    const copyOfItems = state.map(
                        i => {

                            base = new Base();
                            cart = new Cart();

                            restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));
                            addressList = JSON.parse(JSON.stringify(i.appDetails.addressList));
                            cart.restaurant = restaurant;

                            //Remove it after all impl
                            cart.itemTotal = i.appDetails.cart.itemTotal;
                            cart.discountApplied = i.appDetails.cart.discountApplied;
                            // cart.taxesCharger = i.appDetails.cart.taxesCharger;
                            // cart.deliveryFee = i.appDetails.cart.deliveryFee;
                            // cart.toPay = i.appDetails.cart.toPay;
                            // cart.deliveryTip = i.appDetails.cart.deliveryTip;  
                            // cart.defaultAddress = i.appDetails.cart.defaultAddress;
                            // cart.name = action.payload.name;
                            // cart.phone = action.payload.phone;

                            base.appDetails.recentSearches = [...i.appDetails.recentSearches];
                            base.appDetails.addressList = [...addressList];
                            base.appDetails.favourites = i.appDetails.favourites;
                            base.appDetails.cart = cart;
                            base.appDetails.orders = i.appDetails.orders;
                            //base.appDetails.user = i.appDetails.user;
                            base.appDetails.chats = i.appDetails.chats;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.recentSearches = i.appDetails.recentSearches;
                            newBase.push(base);

                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
                case itemActionType.addOrder:
                {
                  console.log("");
                    let newBase = [];
                    let addressList = [];
                    const copyOfItems = state.map(
                        i => {

                            base = new Base();
                            cart = new Cart();

                            restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));
                            addressList = JSON.parse(JSON.stringify(i.appDetails.addressList));
                            cart.restaurant = restaurant;
                            //Remove it after all impl
                            cart.itemTotal = 0;
                            cart.discountApplied = 0;
                        //     cart.taxesCharger = 0;
                        //     cart.deliveryFee = 0;
                        //     cart.toPay = 0;
                        //    cart.deliveryTip = 0;  

                           cart.restaurant[0].menu.category_List.forEach(element => {
                            element.items.forEach(el => {
                                el.selected = 0;
                            });

                            });
                            cart.restaurant[0].itemIdList = new Array;
                            cart.restaurant[0].items = new Array;
                            cart.restaurant[0].numberOfItems =0;
                            cart.defaultAddress = i.appDetails.cart.defaultAddress;
                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;

                            base.appDetails.recentSearches = [...i.appDetails.recentSearches];
                            base.appDetails.addressList = [...addressList];
                            base.appDetails.favourites = i.appDetails.favourites;
                            base.appDetails.cart = cart;
                            base.appDetails.orders = new Array;
                            base.appDetails.orders.push(action.payload,...i.appDetails.orders);
                          //  base.appDetails.user = i.appDetails.user;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.chats = i.appDetails.chats;
                            base.appDetails.recentSearches = i.appDetails.recentSearches;
                            newBase.push(base);

                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
                case itemActionType.reOrder:
                {
                  console.log("");
                    let newBase = [];

                    const copyOfItems = state.map(
                        i => {

                            base = new Base();
                            cart = new Cart();

                            restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));

                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;
                            cart.restaurant = restaurant;
                            cart.defaultAddress = i.appDetails.cart.defaultAddress;


                            cart.restaurant[0].numberOfItems = action.payload.numberOfOrders;

                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;

                            cart.discountApplied = 0;
                            cart.toPay = 0;
                            cart.itemTotal = 0;
                            cart.restaurant[0].itemIdList = [];
                            cart.restaurant[0].items = [];
                            
                                
                                cart.restaurant[0].menu.category_List.forEach(category => {
                                    category.items.forEach(item => {

                                action.payload.orderList.forEach(orderItem => {

                                    if(item.id == orderItem.id){
                                            if(category.id == "recomended"){
                                                item.selected = orderItem.selected;
                                                cart.restaurant[0].itemIdList.push(item.id);
                                            }
                                            if(category.id != "recomended") {

                                                if(!cart.restaurant[0].itemIdList.includes(item.id)){
                                                    cart.restaurant[0].itemIdList.push(item.id);
                                                }
                                                item.selected = orderItem.selected;
                                                cart.restaurant[0].items.push(orderItem);

                                                cart.restaurant[0].items.forEach((element, index) => {
                                                        if(item.id == element.id){
                                                            if(item.haveRestaurantOffer){
                                                                let discountPrice;
                                                                discountPrice = (item.price - item.offerPrice) * cart.restaurant[0].items[index].selected;
                                                                cart.discountApplied = cart.discountApplied + discountPrice;
                                                                cart.toPay = cart.toPay + (item.offerPrice * item.selected);
                                                                
                                                            }else{
                                                                cart.toPay = cart.toPay + (item.price * item.selected);
                                                            }
                                                            cart.itemTotal = cart.itemTotal + (item.price * item.selected);
                                                            // cart.deliveryTip = i.appDetails.cart.deliveryTip;
                    
                                                            // cart.taxesCharger = i.appDetails.cart.taxesCharger;
                                                            // cart.deliveryFee = i.appDetails.cart.deliveryFee;
                                                        }
                                                });
                                                //return;
                                            }

                                        }
                                        if(!cart.restaurant[0].itemIdList.includes(item.id)){
                                            item.selected = 0;
                                        }
                                        });
                                        });

                                });

                            //subcategory changes Need to fix this
                            cart.restaurant[0].menu.category_List.forEach(element => {
                                element.subCategories.forEach(subcategory => {
                                    subcategory.items.forEach(item => {
                                        if(item.id == action.payload.id){
                                            item.selected = item.selected + 1;
                                        }
                                    });
                                });
                            });

                            base.appDetails.addressList = [...i.appDetails.addressList]
                            base.appDetails.favourites = i.appDetails.favourites;
                            base.appDetails.cart = cart;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.orders = i.appDetails.orders;
                            base.appDetails.recentSearches = i.appDetails.recentSearches;
                           // base.appDetails.user = i.appDetails.user;
                            base.appDetails.chats = i.appDetails.chats;
                            newBase.push(base);

                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
                case itemActionType.sendMessage:
                {
                  console.log("");
                    let newBase = [];
                    let addressList = [];
                    const copyOfItems = state.map(
                        i => {

                            base = new Base();
                            cart = new Cart();

                            restaurant = new Restaurant(JSON.parse(JSON.stringify(i.appDetails.cart.restaurant)));
                            addressList = JSON.parse(JSON.stringify(i.appDetails.addressList));
                            cart.restaurant = restaurant;
                            //Remove it after all impl
                            cart.itemTotal = i.appDetails.cart.itemTotal;
                            cart.discountApplied = i.appDetails.cart.discountApplied;
                            // cart.taxesCharger = i.appDetails.cart.taxesCharger;
                            // cart.deliveryFee = i.appDetails.cart.deliveryFee;
                            // cart.toPay = i.appDetails.cart.toPay;
                            // cart.deliveryTip = i.appDetails.cart.deliveryTip;  

                            cart.defaultAddress = i.appDetails.cart.defaultAddress;
                            // cart.name = i.appDetails.cart.name;
                            // cart.phone = i.appDetails.cart.phone;

                            base.appDetails.recentSearches = [...i.appDetails.recentSearches];
                            base.appDetails.addressList = [...addressList];
                            base.appDetails.favourites = i.appDetails.favourites;
                            base.appDetails.cart = cart;
                            base.appDetails.orders = i.appDetails.orders;
                           // base.appDetails.user = i.appDetails.user;
                            base.appDetails.coupouns = i.appDetails.coupouns;
                            base.appDetails.chats = [...i.appDetails.chats,action.payload];
                            base.appDetails.recentSearches = i.appDetails.recentSearches;
                            newBase.push(base);

                        }
                      );
                      console.log("reducer: ", newBase);
                      return [...newBase];
                }
            default:
                return state;
        }
}
