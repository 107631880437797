
import { AppDetails } from "./appDetails";
import { Item } from "./item";
import { Wallet } from "./wallet";

export class Base {

    id: String;
    userType: String;
    name: String;
    email: String;
    mobileNo: String;
    otherContact: String;
    password: String;
    confirmPassword: String;
    authStatus: String;
    role: String;
    appDetails: AppDetails;
    birthday: String;
    gender: String;
    imgUri: String;
    userDeviceToken: String;
    oneTimeItemRequest: Item;
    eligibleForOffer: boolean;
    lastLoginDate: String;
    otp: number;
    myReferralCode: string;
    referredCode: string;
    wallet: Wallet;
    promouser: string;
    
}
